<template>
<v-container>
  <v-skeleton-loader
    v-for="n in 10"
    :key="n"
    class="mx-auto"
    type="list-item"
  ></v-skeleton-loader>
</v-container>
</template>

<script>
export default {
  name: 'LoadingList',
  components: {},
  data: () => ({
  }),
  methods: {
    test: function () {}
  }
}
</script>
